import { on } from 'delegated-events';

import showPopup from './modals';
import initRecaptcha from './recaptcha';

export default function () {
    const newsletter = document.getElementById('newsletter');

    if (!newsletter)
        return;

    newsletter.querySelector('.popup-trigger').addEventListener('click', function () {
        showPopup({ header: 'Zapis do newslettera', body: newsletter.querySelector('.goshop-modal-content').innerHTML, overloadClass: 'standard-newsletter' });

        initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
    });

    newsletter.querySelector('input[type="email"]').addEventListener('keyup', function (e) {
        if ((e.key === "Enter" || e.keyCode === 13) && !window.currentModalInstance) {
            showPopup({ header: 'Zapis do newslettera', body: newsletter.querySelector('.goshop-modal-content').innerHTML, overloadClass: 'standard-newsletter' });

            initRecaptcha(document.querySelector('.goshop-modal .captcha-wrapper'));
        }
    });

}