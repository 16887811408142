import { on } from 'delegated-events';

import { debounce } from './utils/utils';

const searchStorage = window.localStorage;
const searchStorageItems = searchStorage.getItem('searchStorageItems') ? JSON.parse(searchStorage.getItem('searchStorageItems')) : [];

const manageSearchStorage = (enteredValue) => {
    if (enteredValue) {
        if (searchStorageItems.length <= 5 && !searchStorageItems.includes(enteredValue)) {
            searchStorageItems.unshift(enteredValue);
        }

        if (searchStorageItems.length === 6) {
            searchStorageItems.pop();
        }

        searchStorage.setItem('searchStorageItems', JSON.stringify([...searchStorageItems]));
    } else {
        const itemExisting = searchStorage.getItem('searchStorageItems');

        if (itemExisting && itemExisting.length > 0) {
            const elements = JSON.parse(itemExisting);

            return elements;
        }
    }
}

export default function searchEngine() {
    const searchEngine = document.querySelector('#search-engine');

    if (!searchEngine) return;

    const searchInput = searchEngine.querySelector('[name="query"]');
    const searchForm = searchEngine.querySelector('form');
    const suggestions = searchEngine.querySelector('#suggestions');

    const productsWrapper = suggestions.querySelector('.suggestion-result-products');
    const producersWrapper = suggestions.querySelector('.suggestion-result-producers');
    const categoriesWrapper = suggestions.querySelector('.suggestion-result-categories');

    const fillSuggestions = (products, producers, categories) => {
        productsWrapper.innerHTML = '';

        if (products.length > 0) {
            productsWrapper.innerHTML = products.map((item) => {
                return `
                    <li class="suggestion-product">
                        <a href="${item.url}">
                            <em class="size-custom">
                                <img src="${item.image}" alt="${item.name}" />
                            </em>
                            <b>${item.name}</b>
                        </a>
                    </li>
                `;
            }).join('');
        }

        producersWrapper.innerHTML = '';

        if (producers.length > 0) {
            producersWrapper.innerHTML = producers.map((item) => {
                return `
                    <li>
                        <a href="${item.url}">${item.name}</a>
                    </li>
                `;
            }).join('');
        }

        categoriesWrapper.innerHTML = '';

        if (categories.length > 0) {
            categoriesWrapper.innerHTML = categories.map((item) => {
                return `
                    <li>
                        <a href="${item.url}">${item.name}</a>
                    </li>
                `;
            }).join('');
        }
    };

    const searchProducts = (query) => {
        suggestions.classList.add('loading');

        fetch('api/frontend/searchsuggestions', {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify({ query }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                const products = data.products;
                const producers = data.producers;
                const categories = data.categories;

                suggestions.classList.remove('loading');
                fillSuggestions(products, producers, categories);
            })
            .catch(error => {
                suggestions.classList.remove('loading');
                console.log(error);
            });
    };

    searchInput.addEventListener('input', debounce(function (e) {
        const value = e.target.value;

        if (value.length > 1) {
            searchEngine.classList.add('show');
            searchEngine.classList.remove('show-last-phrases');
            searchProducts(value);
        } else {
            searchEngine.classList.remove('show');

            if (manageSearchStorage()) {
                searchEngine.classList.add('show-last-phrases');
            }
            
            fillSuggestions([], [], []);
        }
    }, 500));

    searchInput.addEventListener('focus', function(e) {
        const value = e.target.value;
        const lastSearched = manageSearchStorage();

        if (value.length > 1) {
            searchEngine.classList.add('show');
        } else if (lastSearched) {
            const lastPhrasesWrapper = suggestions.querySelector('.suggestion-last-phrases');

            searchEngine.classList.add('show-last-phrases');
            lastPhrasesWrapper.innerHTML = '';
            lastPhrasesWrapper.innerHTML = lastSearched.map((item) => {
                const text = document.createTextNode(item);
                const html = document.createElement('span');

                html.appendChild(text);

                return "<li><span>" + html.innerHTML.toString() + "</span><i class='delete-hint-suggestion'></i></li>";
            }).join('');
        }
    });

    document.addEventListener('click', function (e) {
        const isClickInside = searchEngine.contains(e.target);

        if (!isClickInside && window.outerWidth > 1279) {
            searchEngine.classList.remove('show');
            searchEngine.classList.remove('show-last-phrases');
        }
    });

    searchForm.addEventListener('submit', function (e) {
        const enteredValue = e.target.elements['query'];

        if (enteredValue.value.length > 1) {
            manageSearchStorage(enteredValue.value);
        }

        manageSearchStorage();
    });

    on('click', '.suggestion-last-phrases span', function () {
        searchInput.value = this.textContent;
        searchForm.submit();
    });

    on('click', '.suggestion-last-phrases .delete-hint-suggestion', function () {
        const clickedItem = this.previousElementSibling.textContent;
        const index = searchStorageItems.indexOf(clickedItem);

        this.parentElement.classList.add('hidden');

        if (index != -1) {
            searchStorageItems.splice(index, 1);
        }

        if (searchStorageItems.length === 0) {
            searchStorage.removeItem('searchStorageItems');
            searchEngine.classList.remove('show');
            searchEngine.classList.remove('show-last-phrases');
            return;
        }

        searchStorage.setItem('searchStorageItems', JSON.stringify(searchStorageItems));
    });
}